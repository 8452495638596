import './App.css';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BgImage from '../src/images/hospital_2.JPG'
import Logo from '../src/images/logo_unimed.png';
import { useEffect, useState } from 'react';
import { CardMedia, Snackbar, Alert, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText, FormLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { Card } from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function App() {
  const url = process.env.REACT_APP_API_URL;
  const authorization = process.env.REACT_APP_API_AUTHORIZATION;
  const apiUrl = process.env.REACT_APP_API_URL_SERVER;
  const adUrl = process.env.REACT_APP_AD_URL;
  const adPassword = process.env.REACT_APP_AD_PASSWORD;
  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;

  const [matricula, setMatricula] = useState("");
  const [identidade, setIdentidade] = useState("");
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [tipoEnvio, setTipoEnvio] = useState('sms');
  const [documentoTipo, setDocumentoTipo] = useState('cpf');

  const [matriculaError, setMatriculaError] = useState(false);
  const [identidadeError, setIdentidadeError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [celularError, setCelularError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dataNascimentoError, setDataNascimentoError] = useState(false);

  const [matriculaErrorMessage, setMatriculaErrorMessage] = useState('');
  const [identidadeErrorMessage, setIdentidadeErrorMessage] = useState('');
  const [cpfErrorMessage, setCpfErrorMessage] = useState('');
  const [celularErrorMessage, setCelularErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [dataNascimentoErrorMessage, setDataNascimentoErrorMessage] = useState('');
  const [tipoEnvioErrorMessage, setTipoEnvioErrorMessage] = useState('');

  const { control, setValue, formState: { errors }, setError, clearErrors, getValues } = useForm();

  const [isValidDate, setIsValidDate] = useState(true); //Data de nascimento válida
  const [openSucesso, setOpenSucesso] = useState(false); //Open alert de sucesso
  const [openError, setOpenError] = useState(false); //Open alert de erro
  const [openErrorPassword, setOpenErrorPassword] = useState(false); //Open alert de erro de senhas diferentes
  const [openErrorToken, setOpenErrorToken] = useState(false); //Open alert de erro de tokens diferentes
  const [resultadoAPI, setResultadoAPI] = useState({}); //Resultado API totvs
  const [openToken, setOpenToken] = useState(false); //Tela de inserir token
  const [openPassword, setOpenPassword] = useState(false); //Tela de trocar senha
  const [tokenRandom, setTokenRandom] = useState(""); //Token
  const [sendingEmail, setSendingEmail] = useState(false); //Loader de espera até enviar e-mail

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  //Handler de delete de mask para celular pessoal
  const handleKeyDown = (e, field) => {
    if (e.key === 'Backspace') {
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;

      if (selectionStart === selectionEnd && selectionStart > 0) {
        const newValue = field.value.slice(0, selectionStart - 1) + field.value.slice(selectionStart);
        const newCursorPosition = selectionStart - 1;
        e.preventDefault();
        field.onChange(newValue);
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }
  };

  //Envio de e-mail com token
  const enviarEmail = async (email, token) => {
    const to = email;
    const subject = "Token de redefinição de senha AD - Unimed Pelotas/RS";
    const text = "Olá! Seu token de redefinição é: " + token + "\n";

    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.set('Content-Type', 'application/json');

    try {
      const body = { to, subject, text };
      const response = await fetch(`${apiUrl}/send-email/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.ok) {
        //console.log("E-mail enviado com sucesso.");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //Envio de SMS com token
  const enviarSMS = async (celular, mensagem) => {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.set('Content-Type', 'application/json');

    try {
      const response = await fetch(`${apiUrl}/send-sms`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          to: celular,
          message: mensagem
        })
      });

      if (response.ok) {
        //console.log('SMS sent successfully');
      } else {
        console.error('Failed to send SMS:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending SMS:', error.message);
    }
  }

  //Fecha tela de token
  const handleCloseTokenValidation = () => {
    setOpenToken(false);
  };

  //Fecha tela da senha
  const handleClosePassword = () => {
    setOpenPassword(false);
  };

  //Abre tela de token
  const handleOpenTokenValidation = () => {
    setOpenToken(true);
  }

  //Abre tela de senha
  const handleOpenPassword = () => {
    setOpenPassword(true)
  }

  // Submit
  const handleSubmitMain = async (e) => {
    e.preventDefault();
  
    // Reset errors
    setMatriculaError(false);
    setIdentidadeError(false);
    setCpfError(false);
    setCelularError(false);
    setEmailError(false);
    setDataNascimentoError(false);
  
    let hasError = false;
  
    // Validation for matricula
    if (matricula.trim().length === 0) {
      setMatriculaError(true);
      setMatriculaErrorMessage('Digite sua matrícula.');
      hasError = true;
    }
  
    // Validation for identidade or cpf based on selection
    if (documentoTipo === 'rg' && identidade.trim().length === 0) {
      setIdentidadeError(true);
      setIdentidadeErrorMessage('Digite seu RG.');
      hasError = true;
    } else if (documentoTipo === 'cpf' && cpf.trim().length === 0) {
      setCpfError(true);
      setCpfErrorMessage('Digite seu CPF.');
      hasError = true;
    }
  
    // Validation for celular
    if (celular.trim().length === 0) {
      setCelularError(true);
      setCelularErrorMessage('Digite seu celular.');
      hasError = true;
    }
  
    // Validation for email
    if (email.trim().length === 0) {
      setEmailError(true);
      setEmailErrorMessage('Digite seu e-mail.');
      hasError = true;
    }
  
    // Validation for dataNascimento
    const isValid = dayjs(dataNascimento).isValid();
    setIsValidDate(isValid);
  
    if (isValid) {
      setDataNascimentoError(false);
      setDataNascimentoErrorMessage("");
    } else {
      setDataNascimentoError(true);
      setDataNascimentoErrorMessage("Digite uma data válida.");
      hasError = true;
    }
  
    if (hasError) {
      return;
    }
  
    const dataNascimentoNova = dataNascimento.split('-');
    const dataNascimentoFinal = dataNascimentoNova[2] + '-' + dataNascimentoNova[1] + '-' + dataNascimentoNova[0];
    const celularNovo = celular.replace('-', '');
  
    try {
      setSendingEmail(true);
  
      let api_response;
      if (documentoTipo === 'rg') {
        api_response = await api_fetch(identidade, matricula, celularNovo, dataNascimentoFinal, email);
      } else if (documentoTipo === 'cpf') {
        api_response = await api_fetch(cpf, matricula, celularNovo, dataNascimentoFinal, email);
      }
  
      if (api_response.status === "Sucesso") {
        const tokenRandom = geraSenhaAleatoria();
        setTokenRandom(tokenRandom);
        if (tipoEnvio === "email") {
          await enviarEmail(email, tokenRandom);
          handleOpenTokenValidation();
        } else if (tipoEnvio === "sms") {
          let destino = "5553" + celular.replace("-", "");
          let texto = "Token de redefinição de senha: " + tokenRandom;
          await enviarSMS(destino, texto);
          handleOpenTokenValidation();
        }
      }
  
      if (api_response.status === "Erro") {
        setOpenError(true);
      }
  
    } catch (error) {
      console.error(error.message);
    } finally {
      setSendingEmail(false);
    }
  };
  

  // Teste de dados com API
  const api_fetch = async (documento, matricula, celular, dataNascimento, email) => {
    let urlNova = "";
    if(documentoTipo==='rg'){
      urlNova = url + '?identidade=' + documento + '&matricula=' + matricula + '&celular=' + celular + '&dtNasc=' + dataNascimento + '&email=' + email;
    } else {
      urlNova = url + '?cpf=' + documento + '&matricula=' + matricula + '&celular=' + celular + '&dtNasc=' + dataNascimento + '&email=' + email;
    }
    const response = await fetch(urlNova, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Basic ${authorization}`,
        'Content-Type': 'application/json'
      })
    })

    const data = await response.json();
    //console.log("Resultado API: ", data);
    setResultadoAPI(data);

    return data;
  }

  // Pega usuários do AD
  const pegaUsers = async () => {
    try {
      const response = await fetch(`${adUrl}`, {
        method: "GET",
        headers: {
          "teste": adPassword,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      return data.mensagem;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  // Gera token 
  const geraSenhaAleatoria = () => {
    let senha = "";
    let characters = "ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz!@#$%&*()[]/";
    let length = 7;
    let count = 0;
    while (count < length) {
      senha += characters.charAt(Math.floor(Math.random() * characters.length));
      count += 1;
    }
    return senha;
  };

  //Submit token
  const handleTokenValidationSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const token = formJson.token;

    if (token === tokenRandom) {
      handleCloseTokenValidation();
      handleOpenPassword();
    } else {
      setOpenErrorToken(true);
    }
  };

  //Submit senhas
  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    if (formJson.senha1 === formJson.senha2 && formJson.senha1.trim().length > 0 && formJson.senha2.trim().length > 0) {
      handleClosePassword();
      handleCloseTokenValidation();

      try {
        const users = await pegaUsers();
        const myNameUser = users.find((obj) =>
          convertToFirstLetterCapitalized(obj.nome) === convertToFirstLetterCapitalized(resultadoAPI.nome)
        );

        if (myNameUser) {
          alterarSenha(myNameUser.usuario, formJson.senha1);
          registerLogUser(myNameUser.usuario);
          setMatricula();
          setIdentidade();
          setCpf();
          setCelular();
          setEmail();
          setDataNascimento();
          setOpenSucesso(true);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setOpenErrorPassword(true);
      }
    } else {
      setOpenErrorPassword(true);
    }
  };

  //Blur token
  const handleBlurToken = async (event) => {
    const inputValue = event.target.value;

    const isValid = dayjs(inputValue).isValid();

    // Update the isValidDate state
    setIsValidDate(isValid);

    // Update the form value and trigger revalidation
    setValue('token', inputValue);

    if (inputValue.trim().length === 0) {
      setError('token', {
        type: 'manual',
        message: 'Token não pode conter somente espaços em branco.',
      });
    } else {
      clearErrors('token');
    }
  };

  //Blur senha1
  const handleBlurPassword = async (event) => {
    const inputValue = event.target.value;
    setValue('senha1', inputValue);

    if (inputValue.trim().length === 0) {
      setError('senha1', {
        type: 'manual',
        message: 'Senha não pode conter somente espaços em branco.',
      });
    } else if (inputValue.length < 8) {
      setError('senha1', {
        type: 'manual',
        message: 'Senha deve conter no mínimo 8 caracteres.',
      });
    } else if (!/(?=.*[a-z])/.test(inputValue) || !/(?=.*[A-Z])/.test(inputValue) || !/(?=.*[0-9])/.test(inputValue) || !/(?=.*[!@#$&*])/.test(inputValue)) {
      setError('senha1', {
        type: 'manual',
        message: 'Requisitos: Letras maiúsculas e minúsculas, números, caracteres especiais (!@#$&*).',
      });
    } else if (/\s/.test(inputValue)) {
      setError('senha1', {
        type: 'manual',
        message: 'A senha não pode conter espaços em branco.',
      });
    } else {
      clearErrors('senha1');
    }
  };

  //Blur senha2
  const handleBlurPassword2 = async (event) => {
    const inputValue = event.target.value;
    setValue('senha2', inputValue);

    if (inputValue.trim().length === 0) {
      setError('senha2', {
        type: 'manual',
        message: 'Senha não pode conter somente espaços em branco.',
      });
    } else if (inputValue.length < 8) {
      setError('senha2', {
        type: 'manual',
        message: 'Senha deve conter no mínimo 8 caracteres.',
      });
    } else if (!/(?=.*[a-z])/.test(inputValue) || !/(?=.*[A-Z])/.test(inputValue) || !/(?=.*[0-9])/.test(inputValue) || !/(?=.*[!@#$&*])/.test(inputValue)) {
      setError('senha2', {
        type: 'manual',
        message: 'Requisitos: Letras maiúsculas e minúsculas, números, caracteres especiais (!@#$&*).',
      });
    } else if (/\s/.test(inputValue)) {
      setError('senha2', {
        type: 'manual',
        message: 'A senha não pode conter espaços em branco.',
      });
    } else {
      clearErrors('senha2');
    }
  };

  //Matrícula
  const handleMatriculaChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setMatricula(value);
    setMatriculaError(false);

    if (value.trim().length === 0) {
      setMatriculaError(true);
      setMatriculaErrorMessage('Digite sua matrícula.');
    }
  };

  //Tipo de documento
  const handleDocumentoTipoChange = (e) => setDocumentoTipo(e.target.value);

  //RG
  const handleIdentidadeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setIdentidade(value);
    setIdentidadeError(false);

    if (value.trim().length === 0) {
      setIdentidadeError(true);
      setIdentidadeErrorMessage('Digite seu RG.');
    }
  };

  //CPF
  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCpf(value);
    setCpfError(false);

    if (value.trim().length === 0) {
      setCpfError(true);
      setCpfErrorMessage('Digite seu CPF.');
    }
  };

  //Celular
  const handleCelularChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{5})(\d{0,4})/, '$1-$2');
    setCelular(formattedValue);
    setCelularError(false);

    if (value.trim().length === 0) {
      setCelularError(true);
      setCelularErrorMessage('Digite seu celular.')
    }
  }

  //E-mail
  const handleEmailChange = (e) => {
    const value = e.target.value.trim();
    setEmail(value);
    setEmailError(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      setEmailError(true);
      setEmailErrorMessage('O campo deve ser um endereço de e-mail válido.');
    }
    else if (value.trim().length === 0) {
      setEmailError(true);
      setEmailErrorMessage('Digite seu e-mail.');
    }
  }

  //Data de nascimento
  const handleDataNascimentoChange = (e) => {
    const value = e.target.value.trim();
    setDataNascimento(value);
    setDataNascimentoError(false);

    const dateOfBirthRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (!dateOfBirthRegex.test(value)) {
      setDataNascimentoError(true);
      setDataNascimentoErrorMessage('O campo deve estar no formato DD/MM/AAAA.');
    }
    else if (value.trim().length === 0) {
      setDataNascimentoError(true);
      setDataNascimentoErrorMessage('O campo não pode estar vazio.');
    }
  }

  //Blur data de nascimento
  const handleBlur = async (event) => {
    const inputValue = event.target.value;
    const isValid = dayjs(inputValue).isValid();
    setIsValidDate(isValid);
    setDataNascimento(inputValue);

    if (isValid) {
      setDataNascimentoError(false);
      setDataNascimentoErrorMessage("");
    } else {
      setDataNascimentoError(true);
      setDataNascimentoErrorMessage("Digite uma data válida.");
    }
  };

  //Tipo envio
  const handleTipoEnvioChange = (e) => {
    setTipoEnvio(e.target.value);
  }

  //Troca senha AD
  const alterarSenha = (usuario, senha) => {
    const url = `${adUrl}`;
    const usuarioParam = encodeURIComponent(usuario);
    const senhaParam = encodeURIComponent(senha);

    const params = new URLSearchParams();
    params.append('usuario', usuarioParam);
    params.append('senha', senhaParam);

    const requestOptions = {
      method: 'PUT',
      headers: {
        'teste': adPassword,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params
    };

    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(data => {
        //console.log(data); 
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  //Registra acesso ao sistema no BD
  const registerLog = async () => {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.set('Content-Type', 'application/json');

    const response = await fetch(`${apiUrl}/logs/`, {
      method: "POST",
      headers: headers
    });
  }

  //Registra log de user ao trocar a senha
  const registerLogUser = async (colaborador) => {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.set('Content-Type', 'application/json');

    const response = await fetch(`${apiUrl}/logs/${colaborador}`, {
      method: "POST",
      headers: headers
    });
  }

  function convertToFirstLetterCapitalized(name) {
    let normalizedName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    let words = normalizedName.split(' ');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    registerLog();
  }, []);

  const [countdown, setCountdown] = useState(20);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timerKey, setTimerKey] = useState(0);

  useEffect(() => {
    let timer;
    if (openToken) {
      setCountdown(20);
      setIsResendDisabled(true);
      timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [openToken, timerKey]);

  const handleResendToken = async () => {
    setIsResendDisabled(true);
    setCountdown(20);
    setTimerKey(prevKey => prevKey + 1);
    if (tipoEnvio === "email") {
      await enviarEmail(email, tokenRandom);
    } else if (tipoEnvio === "sms") {
      let destino = "5553" + celular.replace("-", "");
      let texto = "Token de redefinição de senha: " + tokenRandom;
      await enviarSMS(destino, texto);
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} sx={{ backgroundImage: `url(${BgImage})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundSize: 'cover' }} />
      <Grid item xs={12} sm={8} md={5} sx={{ backgroundColor: '#eeeeee' }}>
        <Paper elevation={5} sx={{ m: 5, p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 5 }}>
          <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none', m: 3, w: 50, h: 50 }}>
            <CardMedia component="img" image={Logo} alt="Logo Unimed Pelotas"></CardMedia>
          </Card>
          <Typography component="h1" variant="h5" sx={{ fontFamily: 'UnimedSans' }}>
            Redefinição de senhas AD
          </Typography>

          <Box component="form" onSubmit={handleSubmitMain} sx={{ mt: 1 }}>
            <TextField sx={{ mt: 3, mb: 2 }} value={matricula} onChange={handleMatriculaChange} label="Matricula" placeholder="Ex: 1234" fullWidth error={matriculaError} helperText={matriculaError && matriculaErrorMessage} inputProps={{ maxLength: 5 }}/>
            <FormControl>
              <FormLabel id="documento-tipo-label">Tipo de documento:</FormLabel>
              <RadioGroup row name="documento-tipo-value" value={documentoTipo} onChange={handleDocumentoTipoChange}>
                <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
                <FormControlLabel value="rg" control={<Radio />} label="RG" />
              </RadioGroup>
            </FormControl>
            {documentoTipo === 'rg' && (
              <TextField sx={{ mt: 3, mb: 2 }} value={identidade} onChange={handleIdentidadeChange} label="RG" placeholder="Ex: 1234567890" fullWidth error={identidadeError} helperText={identidadeError && identidadeErrorMessage} inputProps={{ maxLength: 10 }}/>
            )}
            {documentoTipo === 'cpf' && (
              <TextField sx={{ mt: 3, mb: 2 }} value={cpf} onChange={handleCpfChange} label="CPF" placeholder="Ex: 12345678900" fullWidth error={cpfError} helperText={cpfError && cpfErrorMessage} inputProps={{ maxLength: 11 }}/>
            )}
            <TextField sx={{ mt: 3, mb: 2 }} value={celular} onChange={handleCelularChange} label="Celular pessoal" placeholder="Ex: 91234-5678" fullWidth error={celularError} helperText={celularError && celularErrorMessage} inputProps={{ maxLength: 10 }} onKeyDown={(e) => handleKeyDown(e, { value: celular, onChange: setCelular })} />
            <TextField sx={{ mt: 3, mb: 2 }} value={email} onChange={handleEmailChange} fullWidth label="E-mail pessoal" placeholder="Ex: nome@email.com" type="email" error={emailError} helperText={emailError && emailErrorMessage}/>
            <TextField sx={{ mt: 3, mb: 2 }} value={dataNascimento} onChange={handleDataNascimentoChange} fullWidth label="Data de nascimento" type="date" error={dataNascimentoError} helperText={dataNascimentoError && dataNascimentoErrorMessage} InputLabelProps={{ shrink: true }} onBlur={(e) => handleBlur(e)} />
            <FormControl>
              <FormLabel id="tipo-envio-label">Receber token por:</FormLabel>
              <RadioGroup row name="tipo-envio-value" value={tipoEnvio} onChange={handleTipoEnvioChange}>
                <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                <FormControlLabel value="email" control={<Radio />} label="E-mail" />
              </RadioGroup>
              <FormHelperText>{tipoEnvioErrorMessage}</FormHelperText>
            </FormControl>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} color="primary">
              Verificar dados
            </Button>
            {sendingEmail && <CircularProgress color="primary" sx={{ ml: 5, m: 1 }} />}
          </Box>

          <Dialog
            open={openToken}
            onClose={handleCloseTokenValidation}
            PaperProps={{
              component: 'form',
              onSubmit: handleTokenValidationSubmit,
            }}
          >
            <DialogTitle>Verificação em duas etapas</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>
                Digite o token enviado por E-mail/SMS
              </DialogContentText>
              <Controller name="token" control={control} defaultValue="" rules={{ required: 'Campo obrigatório.' }} render={({ field }) => (<TextField sx={{ mt: 3, mb: 2 }}     {...field} variant="standard" label="Token" placeholder='XXXXX' fullWidth error={!!errors.token} helperText={errors.token && errors.token.message} inputProps={{ maxLength: 15 }} onBlur={handleBlurToken} />)} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseTokenValidation}>Cancelar</Button>
              <Button variant="contained" type="submit">Validar</Button>
              <Button variant="contained" onClick={handleResendToken} disabled={isResendDisabled}>Reenviar Token {isResendDisabled ? `(${countdown})` : ''}</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openPassword} onClose={handleClosePassword} PaperProps={{
            component: 'form',
            onSubmit: handlePasswordSubmit,
          }}
          >
            <DialogTitle>Redefinição de senha</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 3 }}>
                Informe a nova senha. A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra minúscula, uma letra maiúscula, um número e um caractere especial (!@#$%).
              </DialogContentText>

              <Controller name="senha1" control={control} defaultValue="" rules={{ required: 'Campo obrigatório.', pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/, message: 'A senha deve conter pelo menos 8 caracteres, com pelo menos uma letra minúscula, uma letra maiúscula, um número e um caractere especial (!@#$%).' } }} render={({ field }) => (
                <TextField type={showPassword1 ? 'text' : 'password'} sx={{ mb: 3 }} {...field} variant="standard" label="Nova senha" fullWidth error={!!errors.senha1} helperText={errors.senha1 && errors.senha1.message} inputProps={{ minLength: 8, maxLength: 15 }} onBlur={(e) => handleBlurPassword(e)} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />)}
              />

              <Controller name="senha2" control={control} defaultValue="" rules={{ required: 'Campo obrigatório.', pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/, message: 'A senha deve conter pelo menos 8 caracteres, com pelo menos uma letra minúscula, uma letra maiúscula, um número e um caractere especial (!@#$%).' } }} render={({ field }) => (
                <TextField type={showPassword2 ? 'text' : 'password'} sx={{ mb: 3 }} {...field} variant="standard" label="Confirmar senha" fullWidth error={!!errors.senha2} helperText={errors.senha2 && errors.senha2.message} inputProps={{ maxLength: 15 }} onBlur={(e) => handleBlurPassword2(e)} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                />)}
              />

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClosePassword}>Cancelar</Button>
              <Button variant="contained" type="submit">Alterar senha</Button>
            </DialogActions>
          </Dialog>

          {openSucesso ? (<Snackbar open={openSucesso} autoHideDuration={10000} onClose={() => setOpenSucesso(false)}><Alert severity="success" variant="filled">Senha alterada com sucesso!</Alert></Snackbar>) : (null)}
          {openError ? (<Snackbar open={openError} autoHideDuration={5000} onClose={() => setOpenError(false)}><Alert severity="error" variant="filled">{resultadoAPI.status} - {resultadoAPI.mensagem}</Alert></Snackbar>) : (null)}
          {openErrorToken ? (<Snackbar open={openErrorToken} autoHideDuration={5000} onClose={() => setOpenErrorToken(false)}><Alert severity="error" variant="filled">Token inválido!</Alert></Snackbar>) : (null)}
          {openErrorPassword ? (<Snackbar open={openErrorPassword} autoHideDuration={5000} onClose={() => setOpenErrorPassword(false)}><Alert severity="error" variant="filled">Senhas diferentes!</Alert></Snackbar>) : (null)}

          <Typography sx={{ m: 1 }}>O tratamento de dados é realizado conforme a <a href="https://complexodesaude.unimedpelotas.com.br/politica-de-privacidade/">política de privacidade</a> da Unimed Pelotas.</Typography>

        </Paper>
      </Grid>
    </Grid>
  );
}

export default App;